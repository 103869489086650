import type { Router } from 'vue-router'
import type { App } from 'vue'
import fetchState from './globals/fetch_state'
import fetch from './globals/fetch'
import auth from './globals/auth'

type PluginOptions = {
  router: Router
  i18n: any
}

export function nuxtPolyfills(options: PluginOptions) {
  return {
    install(app: App) {
      const axios = app.config.globalProperties.$axios
      const store = app.config.globalProperties.$store

      app.config.globalProperties.$fetchState = fetchState()
      app.config.globalProperties.$fetch = fetch({ app }, app.config.globalProperties.$fetchState)

      app.config.globalProperties.$auth = store.$auth = auth({ axios, store, router: options.router, i18n: options.i18n })
    },
  }
}
