import { Loader } from '@googlemaps/js-api-loader'
import { inject, type InjectionKey, type App } from 'vue'

type GeocodeArgs = {
  lat?: number
  lng?: number
  placeId?: string
}

const key = Symbol('google-maps') as InjectionKey<Loader>

export function useGoogleMaps() {
  function createLoader(app: App) {
    const loader = new Loader({
      apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
      version: 'weekly',
      libraries: ['places', 'geocoding', 'marker', 'maps'],
    })

    app.provide(key, loader)
  }

  function loader() {
    return inject(key)
  }

  let sessionToken: google.maps.places.AutocompleteSessionToken | null = null

  async function places(loader: Loader, input: string, types = ['address'], language = 'nl', country = 'nl') {
    const { AutocompleteSessionToken, AutocompleteService } = await loader.importLibrary('places')

    if (sessionToken === null) {
      sessionToken = new AutocompleteSessionToken()
    }

    const autocompleteService = new AutocompleteService()

    return await autocompleteService.getPlacePredictions({
      input,
      sessionToken,
      componentRestrictions: {
        country,
      },
      language,
      types,
    })
  }

  async function geocode(loader: Loader, { lat, lng, placeId }: GeocodeArgs): Promise<google.maps.GeocoderResult[]> {
    const { Geocoder } = await loader.importLibrary('geocoding')

    const geocodingService = new Geocoder()

    const res = await geocodingService.geocode({
      ...((lat !== undefined && lng !== undefined) && { location: new google.maps.LatLng(lat, lng) }),
      placeId,
    })

    return res.results
  }

  return {
    createLoader,
    loader,
    places,
    geocode,
  }
}
