import md5 from 'md5'
import DistributionTypes from '@/config/DistributionTypes'
import SortingKeys from '@/config/SortingKeys'
import { Location } from '@/types/Location'
import { setCookie } from '@/utils/setCookie'
import { Preferences } from '@capacitor/preferences'

export const state = () => ({
  currentLocation: null,
  temporaryLocation: null,
  locale: null,
  country: null,

  // For saving locations that are out of range, e.g. different countries. Is used in the Not Here Yet modal
  outOfRangeLocation: null,

  // check for 18+ when serving booze
  ageCheckApproved: false,

  specialInstructions: null,

  distributionType: null,

  onLocationIdentifier: null,

  onLocationDirect: false,

  distributionTooltipShown: false,

  selectedSortingKey: SortingKeys.Distance,
  selectedCategories: [],

  selectedRating: null,
  showCategoryPage: false, // whether or not to set OverviewType === Overviewtypes.Category for the marketplace

  filtersTooltipOpen: false,

  tracking: {
    utm_medium: undefined,  // e.g. "la-burger" (most of the time a establishment slug)
    utm_source: undefined,  // e.g. "bestelknop"
    updated: undefined,     // Date, for expiry usage
  },
})

export const getters = {
  location: (state) => {
    const location = state.currentLocation

    if (location === null) {
      return null
    }

    // if source does exist, we hopefully can asume this is a pre localization rework location
    // also house_number should be transformed into houseNumber
    if (('source' in location)) {
      return new Location({
        street: location.street,
        houseNumber: location.house_number ?? null,
        zipcode: location.zipcode,
        city: location.city,
        country: location.country ?? state.country,
        coordinates: {
          lat: location.lat,
          lng: location.lng,
        },
        title: location.title ?? null,
      })
    }

    return new Location(location)
  },

  temporaryLocation: (state) => {
    if (state.temporaryLocation === null) {
      return null
    }

    return new Location(state.temporaryLocation)
  },

  outOfRangeLocation: (state) => state.outOfRangeLocation,

  hasLocation: (state) => state.currentLocation !== null,
  gclid: (state) => state.gclid,

  savedLocations: (state, _getters, _rootState, rootGetters) => {
    // const userAddresses = rootGetters['user/addresses'] ?? state.savedLocations
    // TODO: filter duplicates
    const addresses = [ ...rootGetters['user/addresses'], ...[] ]

    return addresses.map(address => new Location(address))
  },

  // Locations with ID's are from the server
  // if the location does not have an ID, it's a temporary location
  hasSelectedTemporaryLocation: (_state, getters) => !getters.location?.id,

  /*
   * User settings and preferences
   */

  ageCheckApproved: (state) => state.ageCheckApproved,
  instructions: (state) => state.specialInstructions,

  distributionType: (state) => state.distributionType,
  isDelivery: (state) => state.distributionType === DistributionTypes.Delivery,
  isTakeAway: (state) => state.distributionType === DistributionTypes.TakeAway,

  // eindhoven/restaurants/la-burger/tafel-10, should show a time selector
  isOnLocation: (state) => state.distributionType === DistributionTypes.OnLocation,

  // eindhoven/restaurants/la-burger/tafel-10/direct, asap
  isOnLocationDirect: (state) => state.onLocationDirect,

  needsUserLocation: (_state, getters) => getters.isDelivery,

  onLocationIdentifier: (state) => state.onLocationIdentifier,

  distributionTooltipShown: (state) => state.distributionTooltipShown,

  filtersTooltipOpen: (state) => state.filtersTooltipOpen,

  hasActiveFilters: (_state, getters) => getters.selectedCategories.length > 0,

  selectedCategories: (state) => state.selectedCategories,

  showCategoryPage: (state) => state.showCategoryPage,

  selectedSortingKey: (state) => state.selectedSortingKey,

  selectedRating: (state) => state.selectedRating,

  selectedFiltersHash: (state, _, rootState) => {
    return md5(
      `${state.distributionType}-
      ${JSON.stringify(state.currentLocation)}-
      ${JSON.stringify(state.selectedCategories)}-
      ${state.selectedSortingKey}-
      ${rootState.marketplace.searchQuery}-
      ${state.selectedRating}`
    )
  },

  apiEstablishmentsQueryParams: (_state, getters) => {
    const params = {}

    if (getters.selectedSortingKey) params.sort = getters.selectedSortingKey
    if (getters.selectedRating) params.rating = getters.selectedRating
    if (getters.selectedCategories && getters.selectedCategories.length > 0)
      params.categories = getters.selectedCategories

    return params
  },

  appEstablishmentsQueryString: (_state, getters, rootState) => {
    const query = {
      distribution: getters.distributionType,
    }

    if (rootState.marketplace.searchQuery !== null) {
      query.q = rootState.marketplace.searchQuery
    }

    if (getters.selectedSortingKey) query.sort = getters.selectedSortingKey
    if (getters.selectedRating) query.rating = getters.selectedRating

    if (getters.selectedCategories && getters.selectedCategories.length > 0) {
      query['categories[]'] = getters.selectedCategories
    }

    return query
  },

  tracking: (state) => {
    const oneDayAgo = new Date().getTime() - 1 * 24 * 60 * 60 * 1000

    const hasUpdatedTime = state.tracking.updated !== undefined

    if (
      hasUpdatedTime &&
      new Date(state.tracking.updated).getTime() >= oneDayAgo
    )
      return state.tracking

    return undefined
  },
}

export const actions = {
  storeCurrentLocale: ({ commit }, locale) => {
    commit('SET_CURRENT_LOCALE', locale)
  },
  storeCurrentCountry: ({ commit }, country) => {
    commit('SET_CURRENT_COUNTRY', country)
  },
  storeCurrentLocation: ({ commit, state }, location) => {
    commit('SET_CURRENT_LOCATION', location)

    if (__CAPACITOR__) {
      Preferences.set({ key: 'location', value: globalThis.btoa(JSON.stringify(location)) })
    } else {
      if (!import.meta.env.SSR) {
        // note: this breaks the German eszett for example
        // setCookie('location', window.btoa(encodeURIComponent(JSON.stringify(location))), state.country)
        setCookie('location', globalThis.btoa(JSON.stringify(location)), state.country)
      }
    }
  },

  storeOutOfRangeLocation: ({ commit }, location) =>
    commit('SET_OUT_OF_RANGE_LOCATION', location),

  storeTemporaryLocation: ({ commit }, location) => {
    commit('SET_TEMPORARY_LOCATION', { ...location, title: null })
  },

  storeTemporaryLocationComponent: ({ commit }, { key, value }) =>
    commit('SET_TEMPORARY_LOCATION_COMPONENT', { key, value }),

  approveAgeCheck: ({ commit }) => {
    commit('APPROVE_AGE_CHECK')
  },

  storeInstructions: ({ commit }, instructions) =>
    commit('SET_INSTRUCTIONS', instructions),

  storeDistributionType: async ({ commit }, distributionType) => {
    commit('SET_DISTRIBUTION_TYPE', Number(distributionType))

    if (__CAPACITOR__) {
      Preferences.set({ key: 'distribution', value: String(distributionType) })
    } else {
      if (!import.meta.env.SSR) {
        setCookie('distribution', distributionType, state.country)
      }
    }
  },

  storeGCLID: ({ commit }, gclid) => {
    commit('SET_GCLID', gclid)
  },

  storeOnLocationIdentifier: ({ commit }, onLocationIdentifier) => {
    commit('SET_ON_LOCATION_IDENTIFIER', onLocationIdentifier)
  },

  storeOnLocationDirect: ({ commit }, onLocationDirect) => commit('SET_ON_LOCATION_DIRECT', onLocationDirect),

  storeSelectedSortingKey: ({ commit, getters }, selectedSortingKey) => {
    if (getters.selectedSortingKey !== selectedSortingKey)
      commit('SET_SELECTED_SORTING_KEY', selectedSortingKey)
  },

  // Categories
  storeSelectedCategories: ({ commit }, categories) => {
    commit(
      'SET_SELECTED_CATEGORIES',
      categories.map((e) => parseInt(e))
    )

    if (categories.length > 1) commit('SET_SHOW_CATEGORY_PAGE', false)
  },

  toggleCategory: ({ commit, state }, category) => {
    // todo reset marketplace items

    if (state.selectedCategories.includes(category)) {
      commit('REMOVE_SELECTED_CATEGORY', category)
    } else {
      commit('ADD_SELECTED_CATEGORY', category)
    }
    commit('SET_SHOW_CATEGORY_PAGE', false)
  },

  setDistributionTooltipShown: ({ commit }, shown) => {

    commit('SET_DISTRIBUTION_TOOLTIP_SHOWN', shown)

  },

  resetCategories: ({ commit }) => {
    commit('SET_SHOW_CATEGORY_PAGE', false)
    commit('RESET_CATEGORIES')
  },

  selectThisCategoryOnly: ({ commit }, category) => {
    commit('RESET_CATEGORIES')
    commit('SET_SHOW_CATEGORY_PAGE', true)
    commit('ADD_SELECTED_CATEGORY', category)
  },

  setFiltersTooltipOpen: ({ commit }, open) =>
    commit('SET_FILTERS_TOOLTIP_OPEN', open),

  resetFilters: async (
    { commit, getters, dispatch },
    { establishmentTypes } = {}
  ) => {
    // set dist type
    if (getters.distributionType === DistributionTypes.TakeAway) {
      await dispatch('storeSelectedSortingKey', 'distance')
    } else if (getters.distributionType === DistributionTypes.Delivery) {
      await dispatch('storeSelectedSortingKey', 'popularity')
    }

    // reset everything else
    commit('RESET_CATEGORIES')
    if (establishmentTypes) commit('RESET_ESTABLISHMENT_TYPES')
    commit('SET_SHOW_CATEGORY_PAGE', false)
    commit('SET_SELECTED_RATING', null)
  },

  storeTracking: ({ commit }, { utm_source, utm_medium }) => {
    commit('SET_TRACKING', {
      utm_source,
      utm_medium,
      updated: new Date(),
    })
  },
}

export const mutations = {
  SET_CURRENT_LOCALE: (state, locale) => (state.locale = locale),
  SET_CURRENT_COUNTRY: (state, country) => (state.country = country),

  APPROVE_AGE_CHECK: (state) => (state.ageCheckApproved = true),

  SET_CURRENT_LOCATION: (state, location) => (state.currentLocation = location),

  SET_OUT_OF_RANGE_LOCATION: (state, location) => (state.outOfRangeLocation = location),

  SET_TEMPORARY_LOCATION: (state, location) =>
    (state.temporaryLocation = location),

  SET_TEMPORARY_LOCATION_COMPONENT: (state, { key, value }) => {
    if (state.temporaryLocation === null) state.temporaryLocation = {}
    state.temporaryLocation[key] = value
    state.temporaryLocation = { ...state.temporaryLocation } // for reactivity
  },

  SET_GCLID: (state, value) => {
    state.gclid = value
  },

  SET_INSTRUCTIONS: (state, instructions) =>
    (state.specialInstructions = instructions),

  SET_DISTRIBUTION_TYPE: (state, distributionType) =>
    (state.distributionType = distributionType),

  SET_ON_LOCATION_IDENTIFIER: (state, identifier) =>
    (state.onLocationIdentifier = identifier),

  SET_ON_LOCATION_DIRECT: (state, isDirect) =>
    (state.onLocationDirect = isDirect),

  // Sorting keys
  SET_SELECTED_SORTING_KEY: (state, selectedSortingKey) =>
    (state.selectedSortingKey = selectedSortingKey),

  // Categories filter
  RESET_CATEGORIES: (state) => (state.selectedCategories = []),

  SET_SELECTED_CATEGORIES: (state, categories) =>
    (state.selectedCategories = categories),
  ADD_SELECTED_CATEGORY: (state, category) =>
    state.selectedCategories.push(category),
  REMOVE_SELECTED_CATEGORY: (state, category) =>
    (state.selectedCategories = state.selectedCategories.filter(
      (c) => c !== category
    )),

  SET_SELECTED_RATING: (state, rating) => (state.selectedRating = rating),

  SET_SHOW_CATEGORY_PAGE: (state, show) => (state.showCategoryPage = show),

  SET_DISTRIBUTION_TOOLTIP_SHOWN: (state, shown) => (state.distributionTooltipShown = shown),

  SET_FILTERS_TOOLTIP_OPEN: (state, open) => (state.filtersTooltipOpen = open),

  SET_TRACKING: (state, data) =>
    (state.tracking = data),
}
