<script setup lang="ts">
import {computed, onMounted, onServerPrefetch} from 'vue'
import {useHead} from '@unhead/vue'
import {useRoute, useRouter} from 'vue-router'
import {useStore} from '@/stores'
import {useI18n} from 'vue-i18n'
import {useExperiments} from '@/composables/experiments'
import {useRequestHost} from '@/utils/requestHost'

const router = useRouter()
const route = useRoute()

const {t, availableLocales, locale} = useI18n()

const store = useStore()

const host = useRequestHost()

const currentRoutes = computed(() =>
    availableLocales
        .map(locale => ({
          locale,
          name: router.getRoutes().find(r => r.name === `${(route.name as string).slice(0, -2)}${locale}`)?.name,
        }))
        .map(({locale, name}) => {
          const {href} = router.resolve({name, params: route.params, query: route.query})

          return {
            rel: 'alternate',
            hreflang: store.state.session.country === locale ? 'x-default' : `${store.state.session.country}-${locale}`,
            href: `//${host}${href}`,
          }
        })
)

useHead({
  title: t('titles.default'),
  htmlAttrs: {
    lang: locale.value,
  },
  meta: [
    {
      name: 'title',
      content: t('meta.title')
    },
    {
      name: 'description',
      content: t('meta.description')
    },
    {
      name: 'google',
      content: 'notranslate',
    },
    {
      property: 'og:title',
      content: t('titles.default'),
    },
    {
      property: 'twitter:title',
      content: t('titles.default'),
    },
    ...((import.meta.env.VITE_BUILD_ENV !== 'production') ? [
      {
        name: 'robots',
        content: 'noindex',
      }
    ] : []),
  ],
  link: [...currentRoutes.value,],
  script: [
    ...(!__CAPACITOR__ ? [
      {
        src: 'https://consent.cookiebot.com/uc.js',
        'data-cbid': 'dda8dd44-585b-4b2a-999b-817d41687ad6',
        id: 'Cookiebot',
        type: 'text/javascript',
        defer: true,
      }
    ] : []),
  ]
})

const setAppHeightVariable = () => {
  const safeInsetTop = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--sat')) || 0
  const safeInsetBottom = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--sab')) || 0

  const appHeight = window.innerHeight - safeInsetBottom - safeInsetTop

  document.documentElement.style.setProperty('--app-height', `${appHeight}px`)
}

const setScrollbarWidthVariable = () => {
  // Creating invisible container
  const outer = document.createElement('div')
  outer.style.visibility = 'hidden'
  outer.style.overflow = 'scroll' // forcing scrollbar to appear
  outer.style.msOverflowStyle = 'scrollbar' // needed for WinJS apps
  document.body.appendChild(outer)

  // Creating inner element and placing it in the container
  const inner = document.createElement('div')
  outer.appendChild(inner)

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth)

  // Removing temporary elements from the DOM
  outer.parentNode.removeChild(outer)

  document.documentElement.style.setProperty('--scrollbar-width', `${scrollbarWidth}px`)
}

const setRootVariables = () => {
  setScrollbarWidthVariable()
  setAppHeightVariable()
}

onServerPrefetch(async () => {
  const {startExperiments} = useExperiments()
  await startExperiments()
})

onMounted(async () => {
  setRootVariables()

  window.addEventListener('orientationchange', setRootVariables)
  window.addEventListener('resize', setRootVariables)

  if (route.query.gclid) {
    await store.dispatch('session/storeGCLID', route.query.gclid)
  }
})
</script>

<template>
  <RouterView/>
</template>

<style lang="scss">
@import "@/assets/css/main.scss";
</style>
